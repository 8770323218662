.table {
    margin-top: $padding-lg;
    width: 100%;
    th {
        background: $c-primary;
        color: $c-white;
        padding: $padding;
        text-align: left;
        @include fontSize(14);
    }
    tr:first-child {
        td {
            border-top: 1px solid $c-border;
        }
    }
    tr + tr th {
        padding: calc($padding/2) $padding;
        background: $c-border;
        color: $c-text;
    }
    td {
        padding: calc($padding/2) $padding;
        width: 50%;
        border: 1px solid $c-border;
        border-top: none;
        border-left: none;
        vertical-align: top;
        @include fontSize(14);
        color: $c-text;
        &:first-child {
            border-left: 1px solid $c-border;
        }
        &.inner-table {
            padding: 0;
            table {
                border-spacing: 0;
            }
            td {
                border-top: 0;
                border-bottom: 0;
                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
            }
        }
        &.v-center {
            vertical-align: middle;
        }
        &.h-center {
            text-align: center;
        }
        &.head-cell {
            background: #e5e5e5;
            color: #575756;
            font-weight: bold;
        }
        &.blue {
            color: $c-primary
        }
    }
    &--styled {
        td {
            width: auto;
        }
        th {
            text-align: initial;
        }
    }
    &--autowidth {
        td {
            width: auto;
        }
    }

    .actions {
        display: flex;
        > div:first-child {
            margin-right: 10px;
        }
    }
}

.overflow-x-scroller {
    width: 100%;
    @include breakpoint("phone") {
        overflow-x: scroll;
        .table {
            word-break: normal;
            width: auto;
        }
    }
}
