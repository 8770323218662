.login{
    padding: $padding;
    display: block;
    width:500px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &__header{
        color: $c-white;
    }
    &__description{
        padding-bottom: $padding;
    }

    &__footer{
        color: $c-white;
        small{
            display: inline-block;
            margin-top: 10px;
            a{
                color: $c-white;
            }
        }
    }

    &__ssobtn{
        background: $c-secondary;
        color: $c-primary;
        padding: 15px;
        border-radius: 2px;
        position: relative;
        display: block;
        text-align: center;
        margin-bottom: 25px;
        text-decoration: none;
        &:before{
            content: '';
            position: absolute;
            width: 50px;
            height: 100%;
            left: 15%;
            top: 0px;
            background-image: url(/images/bi-towers-full.svg);
            background-position: center;
            background-size: 60%;
            background-repeat: no-repeat;
        }
    }

    &__or{
        overflow: hidden;
        margin-bottom:25px;
        text-align: center;
    }
    &__ortxt{
        display: inline-block;
        text-align: center;
        color: $c-white;
        position: relative;
        text-transform: lowercase;
        &:after{
            content: '';
            height: 1px;
            border-top: 1px solid $c-white;
            width: 50vw;
            position: absolute;
            left: calc(100% + 15px);
            top: 50%;
        }
        &:before{
            content: '';
            height: 1px;
            border-top: 1px solid $c-white;
            width: 50vw;
            position: absolute;
            right: calc(100% + 15px);
            top: 50%;
        }
    }

}

body[class^="brand--auth"]{
    background: url(/images/bg-login.jpg) no-repeat center center;
    background-size: cover;
    .header{
        background: none;
        border: none;
        svg #logo{
            fill: $c-secondary;
        }
        nav li a{
             color:$c-white;
        }
    }

    footer{
        border-top: none;
        p{
            color: $c-white;
            a{
                color: $c-secondary;
            }
        }
    }

}

.cookie-message {
color:$c-primary;
font-size:0.75rem;
width: 100%;
background-color: $c-secondary;
position: absolute;
text-align: center;
bottom:0px;
padding-top:15px;
padding-bottom:15px;
animation: fadeout 10s;
opacity:0%;}

.cookie-message a {color:$c-primary;
font-weight:bold}

/* The toast - position it at the bottom and in the middle of the screen */
.homepage-toast {
  min-width: 250px; /* Set a default minimum width */
  width: 100%;
  margin-top: -23px;
    margin-bottom: 15px;
  background-color: $c-secondary; /* Blue background color */
  color: $c-white; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  z-index: 1; /* Add a z-index if needed */
  top: 30px; /* 30px from the top */
  font-size: 12px;
  }

@media only screen and (max-width: 600px) {
	.homepage-toast {
  min-width: 250px; /* Set a default minimum width */
  width: 100%;
  background-color: #0076BA; /* Blue background color */
  color: $c-white; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: sticky;
  z-index: 1; /* Add a z-index if needed */
  top: -75px; /* 30px from the top */
  left:0px;
  }

  .login__ssobtn:before {
    left: 10%;
}

 }


@keyframes fadeout {
  0% {
    opacity:0%;
  }
  10% {
    opacity:100%;
  }
  90% {
    opacity:100%;
  }
  100% {
    opacity:0%;
  }
}
