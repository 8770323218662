.tx-c {
    text-align: center;
}

.inline {
    display: inline;
}

.js-expand-image-btn {
    position: relative;
}

.js-expand-image-btn:before {
    content: ' ';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 24px;
    width: 24px;
    background: url("/images/expand_icon.svg") no-repeat bottom right;
    background-size: 24px 24px;
}
