input:focus-visible {
    outline: $c-secondary auto 1px;
}

.form{
    &__group{
        position: relative;
        padding-bottom: 20px;
        input:not([type="radio"]):not([type="checkbox"]), textarea, select{
            border: solid 1px $c-white;
            color: #ffffff;
            border-radius: 2px;
            background: none;
            padding: 15px 20px;
            @include fontSize(15);
            width: 100%;
            &::placeholder {
                color: #898885;
                opacity: 1;
            }
        ::placeholder {
            color: #898885;
        }
        }

        label{
            color: $c-white;
        }

        input[type="radio"]{

        }
        small{
            opacity: 0.5;
        }
    }
    &__100width {
        width: 100% !important;
    }
    &__checkboxes{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
    }
    &__checkbox {
        width: 23%;
        display: flex;
        height: 40px;
    }
    &__checkbox{
        input  {
            display: none;
        }
        label{
            position: relative;
            padding-left: 30px;
            text-align: left;
        }
        input + label:before{
            content: '';
            background: none;
            border-radius: 0;
            padding: 5px;
            border: 1px solid $c-secondary;
            width: 8px;
            height: 8px;
            position: absolute;
            left: 0;
            top: 0;
        }
        input:checked + label:after{
            position: absolute;
            left: 3px;
            top: -1px;
            content: '\2713';
        }
    }

    .invalid-feedback{
        @include fontSize(14);
        color: #de4d4d;
        font-weight: normal;
        padding: calc($padding/2) 0 0 0;
        display: inline-block;
    }

}

.form {
    &__admin {
        .form__group {
            input:not([type="radio"]):not([type="checkbox"]), textarea, select{
                border: solid 1px $c-primary;
                color: $c-text;
            }

            label {
                color: $c-text;
            }
        }
    }
}

