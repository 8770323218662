.element {
    padding: $padding 0 0 0;
    display: flex;
    flex-wrap: wrap;
    &--palette,
    &--images {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 0;
        margin-top: -$padding-lg;
    }
    &--palette {
        @include breakpoint("tablet") {
            margin-top: -$padding;
        }
        @include breakpoint("phone") {
            flex-direction: row;
        }
    }

    &--images {
        @include breakpoint("desktop") {
            margin-top: -$padding;
        }
        @include breakpoint("phone") {
            flex-direction: row;
        }
    }
    &--logos {
        display: block;
        clear: both;
        overflow: auto;
        @include breakpoint("desktop") {
            display: flex;
            justify-content: space-between;
        }
    }
    + p {
        margin-top: $padding-lg;
    }
    & + h3 {
        margin-top: 1em;
    }
    h4,
    h3 {
        margin-top: 0;
    }
    &#elementEmbed {
        .imagebox__image {
            position: relative;
        }
    }

    @include breakpoint("desktop") {
        &--narrow {
            width: 80%;
            margin: 0 auto;
        }
    }
}
.paragraph {
    margin: $padding 0;

    &--row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: $padding 5%;
        @include breakpoint("phone") {
            margin: $padding 0;
            flex-direction: column;
        }
    }
    &--col {
        display: flex;
        flex-direction: column;
        flex: 1;
        @include breakpoint("phone") {
            flex: none;
        }
    }
}

.palettebox {
    width: 31%;
    background: $c-background;
    border-radius: 5px;
    overflow: hidden;
    margin-top: $padding-lg;
    display: flex;
    flex-direction: column;
    &--spacer {
        background: transparent;
    }
    @include breakpoint("tablet") {
        display: flex;
        width: 100%;
        margin-top: $padding;
        flex-direction: row;
        flex-wrap: wrap;
    }
    @include breakpoint("phone") {
        display: block;
        flex-direction: column;
    }
    &--border {
        border: 2px solid $c-background;
    }
    &--quarter {
        width: 24%;
        @include breakpoint("tablet") {
            width: 49%;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }
    &--half {
        width: 49%;
        @include breakpoint("phone") {
            width: 100%;
        }
    }
    &__colour {
        padding: $padding;
        height: 0;
        padding-top: 46.25%;
        position: relative;
        background: red;
        color: $c-white;
        &--bordered {
            border: 1px solid $c-border;
            border-radius: 5px 5px 0 0;
        }
        @include breakpoint("tablet") {
            width: 50%;
            padding-top: 0;
            height: auto;
        }
        @include breakpoint("phone") {
            width: 100%;
            padding-top: 46.25%;
        }
        p {
            position: absolute;
            bottom: $padding;
            left: $padding;
            width: calc(100% - #{$padding * 2});
            padding: 0;
            margin: 0;
            color: $c-white !important;
            strong {
                display: block;
                color: $c-white;
            }
        }
    }
    &__content {
        padding: $padding;
        p {
            margin: 0;
            padding: 0;
        }
        @include breakpoint("tablet") {
            width: 50%;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }

    &__swatches {
        padding: 10px 5px;
        margin: auto 0px 0 0;
        background: $c-white;
        border: 1px solid $c-background;
        text-align: center;
        border-radius: 0 0 5px 5px;
        img {
            max-width: 100%;
            height: auto;
        }
        @include breakpoint("tablet") {
            flex-basis: 100%;
        }
        @include breakpoint("phone") {
            flex-basis: auto;
        }
    }
}

.typographybox {
    width: 100%;
    padding: $padding;
    background: $c-background;
    border-radius: 5px;
    display: flex;
    align-items: center;
    + .typographybox {
        margin-top: $padding;
    }
    &__heading {
        width: 20%;
        padding: $padding;
        h3 {
            @include fontSize(14);
            color: $c-text;
            text-transform: uppercase;
        }
        @include breakpoint("tablet") {
            width: 100%;
            padding: 0;
        }
    }
    &__content {
        img {
            max-width: 100%;
        }
        p {
            margin: 0;
            padding: 0;
        }
    }
    @include breakpoint("tablet") {
        display: block;
    }
    object {
        max-width: 100%;
    }
}

.palettetable {
    width: 100%;
    padding: 0 $padding;
    background: $c-background;
    border-radius: 5px;
    &__row {
        padding: $padding 0;
        border-bottom: 1px solid $c-border;
        display: flex;
        flex-wrap: wrap;
        color: $c-text;
        align-items: center;
        justify-content: stretch;
        &:last-child {
            border: none;
        }
        @include breakpoint("phone") {
            display: block;
            overflow: auto;
        }
    }
    &__title,
    &__cmyk,
    &__rgb,
    &__hex {
        padding-left: $padding-lg;
        flex-basis: 0;
        flex-grow: 1;
        @include breakpoint("desktop") {
            @include fontSize(14);
            line-height: 1.8rem;
        }
        @include breakpoint("phone") {
            padding: calc($padding/2) 0 0 0;
        }
    }
    &__colour {
        width: 50px;
        height: 50px;
        padding: 0;
        flex-basis: 50px !important;
        border-radius: 5px;
        background: red;
        @include breakpoint("phone") {
            height: 50px;
            width: 100%;
            padding-bottom: calc($padding/2);
        }
    }
    &__title {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.logobox {
    display: flex;
    background: $c-background;
    border: 1.5px solid $c-background;
    width: calc(30% - #{$padding-lg});
    height: 50%;
    margin-bottom: $padding-lg;
    border-radius: 5px;
    overflow: hidden;
    float: left;
    flex-direction: column;

    @include breakpoint("desktop") {
        width: 31%;
        float: none;
    }
    @include breakpoint("tablet") {
        width: 48%;
    }
    @include breakpoint("phone") {
        width: 100%;
    }
    &:first-child {
        width: 70%;
        margin-right: $padding-lg;

        @include breakpoint("desktop") {
            width: 31%;
            margin-right: 0;
        }

        @include breakpoint("tablet") {
            width: 48%;
        }

        @include breakpoint("phone") {
            width: 100%;
        }
        .logobox__image {
            height: 0;
            padding-top: 66.25%;
            position: relative;

            @include breakpoint("desktop") {
                padding-top: 56.25%;
            }
        }
    }
    &__image {
        height: 0;
        padding-top: 56.25%;
        position: relative;
        background: $c-white;
        z-index: 0;
        img {
            max-width: calc(100% - #{$padding * 2});
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include breakpoint("tablet") {
                width: calc(100% - #{$padding * 2});
            }
        }
    }
    &__content {
        padding: $padding;
        flex: 1 0 auto;
        h3 {
            @include fontSize(14);
            color: $c-primary;
            text-transform: uppercase;
            margin-bottom: calc($padding/2);
        }
    }
}

.imagebox {
    width: 31%;
    background: $c-background;
    border: 1px solid $c-background;
    border-radius: 5px;
    overflow: hidden;
    margin-top: $padding-lg;
    &--equalheight {
        display: flex !important;
        flex-direction: column;
        .imagebox__image {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &.imagebox--noborder {
        border-width: 0;
    }

    &.imagebox--fullwidth {
        width: 100%;
        @include breakpoint("desktop") {
            display: block;
            width: 100%;
            margin-top: $padding;
        }
    }
    &.imagebox--twothirds {
        width: 66%;
        @include breakpoint("desktop") {
            display: block;
            width: 50%;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }
    &.imagebox--half {
        width: 49%;
        @include breakpoint("desktop") {
            display: block;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }
    &.imagebox--quarter {
        width: 23%;
        @include breakpoint("desktop") {
            width: 48%;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }
    &.imagebox--threequarters {
        width: 74%;
        @include breakpoint("desktop") {
            width: 48%;
        }
        @include breakpoint("phone") {
            width: 100%;
        }
    }

    &--fixed {
        img {
            max-width: 389px;
            margin: 0 auto;
        }
        &.imagebox--half {
            img {
                max-width: 616px;
            }
        }
    }
    &--padded {
        .imagebox__image {
            padding: $padding;

            @include breakpoint("tablet") {
                padding: calc($padding/2);
            }
        }
        &-lg {
            .imagebox__image {
                padding: $padding-lg $padding-lg * 2;

                @include breakpoint("desktop") {
                    padding: $padding 5%;
                }

                @include breakpoint("tablet") {
                    padding: $padding;
                }
            }
        }
    }

    &--smallerimage {
        .imagebox__image img {
            width: 830px;
            max-width: 66%;
            margin: 0 auto;

            @include breakpoint("desktop") {
                width: 100%;
                max-width: inherit;
            }
        }
    }

    &--mediumimage {
        .imagebox__image img {
            width: 830px;
            max-width: 33%;
            margin: 0 auto;

            @include breakpoint("desktop") {
                width: 100%;
                max-width: inherit;
            }
        }
    }

    &--completefullwidth {
        width: 100%;
        .imagebox__image {
            width: 100%;
        }
        .imagebox__image img {
            width: 100%;
            max-width: none;
            margin: 0 auto;
        }
    }
    &--short {
        .imagebox__image {
            text-align: center;
            img {
                max-height: 200px;
                margin: auto;
                width: auto;
            }
        }
    }
    &--pullleft {
        margin-right: auto;
        margin-left: 3.5%;
        @include breakpoint("desktop") {
            margin: 0;
        }
    }

    @include breakpoint("desktop") {
        // display: flex;
        width: 48%;
        margin-top: $padding;
    }
    @include breakpoint("phone") {
        width: 100%;
        display: block;
    }
    &__image {
        background: $c-white;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__content {
        padding: $padding;
        p {
            margin: 0;
            padding: 0;
            strong {
                color: $c-primary;
                font-weight: bold;
            }
        }
        h3 {
            color: $c-primary;
            @include fontSize(14);
            margin-bottom: 5px;
        }
    }
    img {
        max-width: 100%;
    }
}

.brilliant-gold {
    color: #f19700 !important;
}

.premium-orange {
    color: #f2650f !important;
}

.inspiring-yellow {
    color: #fed123 !important;
}

.guardian-blue {
    color: #001e55 !important;
}

.comfort-grey {
    color: #7a99ac !important;
}

*.debug {
    background-color: red;
    .green {
        background-color: lightgreen;
    }
    .blue {
        background-color: lightblue;
    }
}
