$font-size: 16;
//breakpoints
@mixin breakpoint($class) {
  @if $class == phone {
    @media (max-width: 640px) {
      @content;
    }
  } @else if $class == tablet {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $class == desktop {
    @media (max-width: 1366px) {
      @content;
    }
  }
}

@mixin containerize {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
}

@function rem($px) {
  @return #{calc($px / $font-size)}rem;
}

//noinspection CssOverwrittenProperties
@mixin fontSize($size: 16) {
  font-size: #{$size}px;
  font-size: rem($size);
}
