.guide {
    display: flex;
    padding: $header 0 0 0;
    flex-wrap: wrap;

    @include breakpoint("tablet") {
        display: block;
    }
    p {
        @include fontSize(14);
        color: $c-text;
        &.p--standfirst {
            font-weight: 700;
            @include fontSize(18);
        }
    }
    h2 {
        @include fontSize(25);
    }
    h3 {
        @include fontSize(20);
    }
    aside {
        width: 20%;
        background: $c-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - #{$header} - #{$footer});
        border-right: 1px solid $c-border;

        @include breakpoint("tablet") {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid $c-border;
            min-height: initial;
            display: block;
            height: auto;
        }
        nav {
            padding: $padding-lg;
            overflow: auto;
            @include breakpoint("desktop") {
                padding: $padding;
            }
            @include breakpoint("tablet") {
                overflow: hidden;
            }
            > ul {
                list-style-type: none;
                list-style: none;
                padding: 0;
                margin: 0 0 $padding-lg 0;
                position: relative;
                @include breakpoint("tablet") {
                    margin: 0;
                    &:first-child:after {
                        content: "\25BC";
                        position: absolute;
                        right: -$padding;
                        top: -$padding;
                        transition: all 0.5s ease-in-out;
                        color: $c-secondary;
                        // width: 100%;
                        // text-align: right;
                        @include fontSize(18);
                        cursor: pointer;
                        padding: $padding;
                    }
                    &.active {
                        li a,
                        li a.active {
                            display: block;
                            padding: 0 0 $padding 0;
                        }
                        &:first-child:after {
                            transform: rotate(180deg);
                        }
                        ul {
                            padding-left: $padding;
                            li a.active {
                                color: $c-secondary;
                                @include fontSize(14);
                            }
                        }
                    }
                }
                li {
                    list-style: none;
                    list-style-type: none;
                    a {
                        @include breakpoint("tablet") {
                            display: none;
                            width: calc(100% - #{$padding-lg});
                        }
                        text-decoration: none;
                        color: $c-text;
                        display: block;
                        padding: 0 0 $padding 0;
                        @include fontSize(14);

                        &.active {
                            color: $c-primary;
                            font-weight: bold;
                            @include breakpoint("tablet") {
                                display: block;
                                padding-bottom: 0;
                                @include fontSize(18);
                            }
                        }
                        &.parent {
                            color: $c-primary;
                            font-weight: bold;
                        }
                        &:hover {
                            color: $c-secondary;
                        }
                    }
                }
                ul {
                    padding-left: $padding;
                    margin-bottom: $padding;
                    li a.active {
                        color: $c-secondary;
                        @include breakpoint("tablet") {
                            color: $c-primary;
                        }
                    }
                    @include breakpoint("tablet") {
                        padding-left: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
    .sections {
        width: 80%;
        background: $c-white;
        height: calc(100vh - #{$header} - #{$footer});
        padding: $padding-lg;
        overflow: auto;

        @include breakpoint("desktop") {
            padding: $padding;
        }
        @include breakpoint("tablet") {
            width: 100%;
            height: initial;
        }
        section {
            @include containerize();
            padding-bottom: $padding-lg;
            overflow: auto;
        }

        &--grey {
            background: $c-background-alt;
            section:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
