.header {
    background: $c-primary;
    padding: $padding $padding-lg;
    color: $c-secondary;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    height: 90px;

    @include breakpoint("desktop") {
        padding-right: $padding;
    }
    @include breakpoint("tablet") {
        padding: $padding;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1260px;
        margin: auto;
        padding: 0 70px;
    }
    &__logo { 
        display: block;
        height: 50px;
        overflow: hidden;
        img,
        svg {
            height: 40px;
        }
        @include breakpoint("tablet") {
            margin-left: $padding * 1.5;
        }
    }
    &__home {
        position: absolute;
        padding: 10px;
        text-indent: -999999px;
        overflow: hidden;
        width: 40px;
        border-right: 1px solid $c-border;
        height: 100%;
        top: 0;
        left: 0;
        background: url(/images/icon_home.svg) no-repeat center center;
        background-size: 30px;
    }
    &__nav {
        display: flex;
        color: $c-secondary;
        ul {
            list-style: none;
            padding: 0;
            margin: 0 0 0 $padding * 1.5;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include breakpoint("tablet") {
                margin-left: 0;
                border-bottom: 1px solid $c-border;
            }
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: center;
                line-height: normal;
                a {
                    color: $c-secondary;
                    @include fontSize(14);
                    text-decoration: none;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: block;
                    &.btn--icon {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.loggedin {
    @include breakpoint("tablet") {
        .header nav {
            ul {
                position: absolute;
                top: $header;
                left: 0;
                width: 100%;
                background: $c-white;
                border-top: 1px solid $c-border;
                // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
                top: 90px;
                padding-top: 10px;
                z-index: 9;
                display: none;
                li {
                    a {
                        padding: calc($padding/2) $padding;
                        &.btn--icon {
                            // margin-left: 0;
                            margin: $padding;
                        }
                    }
                }
            }
            // for logged in button
            a.active + ul {
                display: block;
                .btn--icon {
                    margin: 20px;
                }
            }
        }
    }
}

.brand--dashboard .header__logo {
    margin-left: 0;
}