.footer{
    padding: $padding;
    background: $c-primary;
    @include breakpoint('phone'){
        padding: calc($padding/2);
    }
    p{
        text-align: center;
        padding: 0;
        margin: 0;
        color: $c-secondary;
        @include fontSize(12);
        a{
            color: $c-secondary;
            font-weight: bold;
        }
        @include breakpoint('phone'){
            line-height: 2em;
        }
    }
}
