.home{
    &__container{
        margin: -$padding-lg;
        height: calc(100vh - #{$footer} - #{$header});
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        @include breakpoint('tablet'){
            height: auto;
            flex-direction: column-reverse;
            justify-content: flex-start;
            width: 100%;
            margin: -50px 0;
        }

    }
    &__homeMainImage{
            width: 100%;
            background: url(/images/bi-landing.png) no-repeat left center;
            background-size: cover;
            height: 100%;
            @include breakpoint('tablet'){
                width: calc(100% + #{$padding-lg * 2});
                margin-top: 20px;
                flex-basis: 300px;
                flex-grow: 0;
                margin: 0  -#{$padding-lg};
            }
            @include breakpoint('phone'){
                margin: 0  -#{$padding};
                width: calc(100% + #{$padding * 2});
                flex-basis: 200px;
            }
    }
}

.imageRightContainer {
    width: 100%;
    background: url(/images/bi-landing.png) no-repeat left center;
    background-size: cover;
    height: 100%;
    @include breakpoint('tablet'){
        width: calc(100% + #{$padding-lg * 2});
        margin-top: 20px;
        flex-basis: 300px;
        flex-grow: 0;
        margin: 0  -#{$padding-lg};
    }
    @include breakpoint('phone'){
        margin: 0  -#{$padding};
        width: calc(100% + #{$padding * 2});
        flex-basis: 200px;
    }
}
.expiredContainer {
    padding: 5%;
}


