@font-face {
    font-family: 'Boehringer Forward';
    src: url('../fonts/BoehringerForwardText.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead-Bd.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead-BdIt.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead-It.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead-Md.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead-MdIt.woff2') format('woff2'),
         url('../fonts/BoehringerForwardHead.woff2') format('woff2'),
         url('../fonts/BoehringerForwardText-Bd.woff2') format('woff2'),
         url('../fonts/BoehringerForwardText-BdIt.woff2') format('woff2'),
         url('../fonts/BoehringerForwardText-It.woff2') format('woff2'),
         url('../fonts/BoehringerForwardText-Md.woff2') format('woff2'),
         url('../fonts/BoehringerForwardText-MdIt.woff2') format('woff2'),
}

// Typography
$font-family-sans-serif: "Boehringer Forward", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$c-white: #ffffff !default;
$c-background: #ffffff !default;
$c-primary: #08312A !default;
$c-secondary: #00E47C !default;
$c-border: #e5e5e5 !default;
$c-text: #575756 !default;
$c-background-alt: #eeeeee !default;

$padding: 20px;
$padding-lg: 50px;

$header: 90px;
$footer: 70px;
