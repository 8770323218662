h1,
.h1 {
    @include fontSize(55);
    margin-top: 0;
    line-height: 1.1em;
    margin-bottom: 20px;
    @include breakpoint("tablet") {
        @include fontSize(48);
    }
}

h2,
.h2 {
    @include fontSize(43);
    margin-top: 0;
    line-height: 1.1em;
    margin-bottom: 20px;
}
h3,
.h3 {
    @include fontSize(25);
    margin-top: 0;
    line-height: 1.1em;
    margin-bottom: 20px;
}

h4 {
    margin-bottom: 0.5em;
}

p,
.p {
    @include fontSize(18);
    margin-top: 0;
    margin-bottom: $padding;
    line-height: 1.8rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.p--standfirst {
    @include fontSize(20);
}

.text {
    &--centered {
        text-align: center;
    }
}

.alert {
    border: 2px solid $c-border;
    text-align: center;
    display: block;
    padding: $padding;
    strong {
        color: $c-primary;
    }
}

p.stacked {
    span {
        background: $c-border;
        display: inline-block;
        padding: 0px 10px;
        @include fontSize(16);
        margin: 5px 0;
    }
    strong {
        color: $c-primary;
    }
}
li p > strong:first-child {
    color: $c-primary;
}
section {
    ul,
    ol {
        margin-top: 0;
        margin-bottom: $padding;
        line-height: 1.8rem;
        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
        ul,
        ol {
            margin-top: -$padding;
        }
    }
}

sup {
    font-size: 0.5em;
    &.larger {
        font-size: 1em;
        line-height: normal;
        position: relative;
        bottom: -3px;
    }
}

p > a {
    color: $c-primary;
    font-weight: bold;
    text-decoration: none;
}

.capitalise {
    text-transform: uppercase;
}

// Font Weights
.w-regular {
    font-weight: normal;
}

.c-secondary {
    color: $c-secondary;
}
