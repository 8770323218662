* {
    box-sizing: border-box;
}
html,
body {
    background: $c-background;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Boehringer Forward", sans-serif;
    @include fontSize(16);
    color: $c-primary;
}

main {
    min-height: calc(100vh - #{$footer});
    padding: $padding-lg;
    display: block;
    padding-top: calc(#{$header} + #{$padding-lg});
    @include breakpoint("phone") {
        padding: $padding;
        padding-top: calc(#{$header} + #{$padding-lg});
    }
}

.container {
    @include containerize();
    &--flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.container-highlight {
    background: $c-background;
    padding: 2em;
    border-radius: 5px;
    margin-bottom: $padding;
    &.active {
        display: block;
    }
    strong {
        color: $c-primary;
    }
    a {
        color: $c-secondary;
    }
    ul {
        padding-left: 1em;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .imagebox {
        background: #ffffff;
        border: 2px solid #ffffff;
        &__image {
            background: $c-background;
        }
    }
    h4,
    h3 {
        margin-top: 0;
    }
    object,
    img {
        max-width: 100%;
    }
    p:last-child {
        margin-bottom: 0;
    }
    &:last-of-type {
        // margin-bottom: 0;
    }
}
.flex-half {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: $padding 0;
    & > * {
        width: 49%;
        @include breakpoint("tablet") {
            width: 100%;
        }
    }
    .element {
        margin-bottom: 0;
        @include breakpoint("tablet") {
            margin-bottom: $padding;
        }
    }
    & > .container-highlight {
        margin-bottom: 0;
        @include breakpoint("tablet") {
            margin-bottom: $padding;
        }
    }
}
