.embed {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // display: none;
    display: block;
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
    // &.active {
    // display: block;
    // }
    &-parent {
        position: relative;
    }
}
