.btn {
    display: inline-block;
    border-radius: 2px;
    width: 100%;
    padding: 10px $padding-lg;
    background: $c-secondary;
    color: $c-primary;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    text-align: center;
    @include fontSize(18);
    border: none;
    &--outline {
        background: none;
        border: 1px solid $c-white;
    }
    &--icon {
        padding: calc($padding/4) calc($padding/4) calc($padding/4) calc($padding * 1.5);
        background: none;
        font-family: "Boehringer Forward", sans-serif;
        background-size: $padding;
        &-back {
            background: url(/images/icon_back.svg) no-repeat left center;
        }
        &-user {
            background: url(/images/icon_user.svg) no-repeat left center;
        }
    }
    &--icon-right {
        padding: calc($padding/4) calc($padding * 1.5) calc($padding/4) calc($padding/4);
    }
    &--sidebar {
        width: 100%;
        border-radius: 0;
        padding: $padding * 1.5 $padding;
        background: $c-primary;
        transition: background-color 1s ease-in-out;
        font-family: "Boehringer Forward", sans-serif;
        @include fontSize(14);
        @include breakpoint("tablet") {
            padding: $padding;
        }
        &:before {
            content: "";
            background: url(/images/icon_download.svg) no-repeat left center;
            background-size: contain;
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: calc($padding/2);
            margin-left: calc(0px - $padding/2);
        }
        &:hover {
            background-color: $c-secondary;
        }
        &-slimline {
            padding: $padding * 0.75 $padding;
            border-top: 1px solid #2c4677;
        }
    }

    &--mobile {
        display: none;
        height: 3px;
        width: 30px;
        background: $c-primary;
        position: relative;
        padding: 0;
        transition: all 0.5s ease-in-out;
        margin: 20px 0;
        &:before,
        &:after {
            height: 30px;
            width: 100%;
            content: "";
            background: none;
            position: absolute;
            left: 0;
            transition: all 0.5s ease-in-out;
        }
        &:before {
            top: -10px;
            border-top: 3px solid $c-primary;
        }
        &:after {
            bottom: -10px;
            border-bottom: 3px solid $c-primary;
        }
        &.active {
            background: none !important;
            &:before {
                transform: rotate(45deg);
                transform-origin: left top;
            }
            &:after {
                transform: rotate(-45deg);
                transform-origin: bottom left;
            }
        }
        @include breakpoint("tablet") {
            position: absolute;
            right: 20px;
            top: 25px;
        }
    }
}

.loggedin {
    .btn--mobile {
        @include breakpoint("tablet") {
            display: inline-block;
        }
    }
}

.pointer {
    cursor: pointer;
}

.btn--next{
    width: 35px;
    height: 35px;
    margin-right: 50px;
    position: relative;
    float: right;
    background: url(/images/next_btn.svg) no-repeat left center;
    background-size: contain;
    cursor: pointer;
}
