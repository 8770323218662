// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,700");

@import "~photoswipe/src/css/main.scss";
@import "~photoswipe/src/css/default-skin/default-skin.scss";

// Variables
@import "variables";
@import "mixins";
@import "layout";
@import "header";
@import "footer";
@import "button";
@import "form";
@import "grid";
@import "login";
@import "typography";
@import "guide";
@import "element";
@import "utility";
@import "table";
@import "embed";

@import "home";

main img:not(.no-expand) {
    cursor: pointer;
}

.rightImageContainer {
    width: 50%;
    background: url(/images/bi-landing.png) no-repeat left center;
    background-size: cover;
    height: 100%;
    @include breakpoint('tablet'){
        width: calc(100% + #{$padding-lg * 2});
        margin-top: 20px;
        flex-basis: 300px;
        flex-grow: 0;
        margin: 0  -#{$padding-lg};
    }
    @include breakpoint('phone'){
        margin: 0  -#{$padding};
        width: calc(100% + #{$padding * 2});
        flex-basis: 200px;
    }
}