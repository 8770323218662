.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: $padding-lg 0;

    &--narrow {
        max-width: 900px;
        margin: auto;

        .grid__item {
            margin-bottom: 4%;
        }
    }

    @include breakpoint("phone") {
        padding: 0;
    }

    &__aspect {
        padding-bottom: 98%;
        background-position: center;
        background-size: cover;
    }

    &__item {
        background: #F6F5F3;
        flex-basis: 23%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 2%;
        height: auto;
        position: relative;

        @include breakpoint("phone") {
            flex-basis: 100%;
            width: 100%;
            margin-bottom: $padding-lg;
            margin-left: 0;
        }

        img {
            width: 70%;
            margin: auto;
            display: flex;
            align-items: center;
            height: 100%;
            object-fit: contain;
        }

        &--narrow {
            .grid__content {
                padding: 20px;
            }

            .grid__aspect {
                padding-bottom: 275px;
            }
        }

        &--overlayed {
            height: 250px;
            overflow: hidden;

            @include breakpoint("tablet") {
                flex-basis: 48%;
                width: 48%;
                margin: 0 1%;
                margin-bottom: 2%;
            }

            @include breakpoint("phone") {
                flex-basis: 100%;
                width: 100%;
                margin: 0 0 $padding 0;
            }

            .grid__overlay h2 {
                opacity: 1;
                transition: none;
                @include fontSize(40);
            }

            .grid__content {
                display: none;
            }

            .grid__button {
                text-indent: -100000px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &[data-brand="Agnostic"],
        &[data-brand="Jardiance"],
        &[data-brand="Beyond-100-Years"] {
            .grid__title {
                sup {
                    display: none;
                }
            }
        }


        &[data-brand="Agnostic"] .grid__title:before {
            content: "BI Brand ";
        }

        &[data-brand="Spiolto"],
        &[data-brand="OFEV"],
        &[data-brand="Pradaxa"] {
            .grid__title {
                text-transform: uppercase;
            }
        }
    }

    &__image {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        flex-direction: column;
        justify-content: flex-end;
        padding: $padding * 1.5;
        background: $c-white;
        display: block;
        border-top: 2px solid $c-border;
    }

    &__title {
        @include fontSize(20);
        color: $c-primary;
        padding: 0;
        margin: 0 0 calc($padding/2) 0;
        font-weight: bold;
    }

    &__description {
        @include fontSize(14);
        color: $c-text;
        padding: 0;
        margin: 0;
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: $padding;
        text-align: center;
        display: flex;
        opacity: 1;
        top: 0;
        left: 0;
        cursor: pointer;

        >* {
            opacity: 0;
            align-self: center;
            transition: none;
        }

        &:hover {
            >* {
                opacity: 1;
                transition: all 0.5s ease-out;
                transition-delay: 1s;
            }
        }

        h2 {
            color: $c-white;
            @include fontSize(25);
            z-index: 2;
        }
    }

    &__button {
        z-index: 2;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
